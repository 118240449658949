import * as React from "react";
import { Link } from "gatsby";

import Icons from '../../assets/vector/icons';

import Card_Waifu from './cards/waifu';
import Card_Tractor from './cards/tractor';

import loli from '../../assets/img/farmHouse/loli.webp';
import tractor from '../../assets/img/farmHouse/tractor.webp';

const FHView = () => {
    return (
        <div className="p-5">
            <div className="flex mb-2">
                <div className="rounded-lg bg-primarypeach p-2 shadow-lg">
                    <Link to="/farmHouse">
                        <button className="rounded-lg bg-primaryDark text-white py-1 px-2 font-semibold text-lg">BACK</button>
                    </Link>
                </div>
            </div>
            <div className="text-2xl font-bold flex items-center mb-2">
                <span>FARM HOUSE NAME</span> <span className="ml-2"><Icons.pencil/></span>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                <div>
                    <div className="bg-primaryDark rounded-lg p-3 font-semibold text-center shadow-xl mb-4">
                        <div className="text-2xl text-white">
                            CAPACITY
                        </div>
                        <div className="text-xl text-white">
                            10 <span className="text-primaryskyblue">Farmer Waifus</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-8">
                        <Card_Tractor />
                        <Card_Tractor />
                        <Card_Tractor />
                        <Card_Tractor />
                    </div>
                </div>
                <div>
                    <div className="bg-primaryDark rounded-lg p-3 font-semibold text-center shadow-xl mb-4">
                        <div className="text-2xl text-white">
                            POWER
                        </div>
                        <div className="text-xl text-white">
                            875 <span className="text-primarypeach">WP</span>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-8">
                        <Card_Waifu />
                        <Card_Waifu />
                        <Card_Waifu />
                        <Card_Waifu />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FHView;