import * as React from "react";
import Layout from "../../components/layouts/Layout";
import backgroundHex from "../../assets/img/backgroundSections/background.webp";
import FarmHouseView from "../../components/FarmHouse/view_page";

const View = () => {
    return (
        <Layout background={backgroundHex}>
            <FarmHouseView />
        </Layout>
    )
}

export default View;