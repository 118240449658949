import React from "react";

import Background_time from '../../elements/background_time';
import GetImageAdn from "../../elements/getImageAdn";

import start1 from "../../../assets/vector/startWaifu1.svg"
import start2 from "../../../assets/vector/startWaifu2.svg"
import start3 from "../../../assets/vector/startWaifu3.svg"
import start4 from "../../../assets/vector/startWaifu4.svg"
import start5 from "../../../assets/vector/startWaifu5.svg"

const Waifu = ({element}) => {
    const durability = {
        now: parseInt(((Date.now() / 1000) - element?.birthday) / 84600),
        complete: parseInt((element?.aliveUntil - element?.birthday) / 84600)
    }
    const startValue = () => {
        let value = Math.max(23); //...element.waifuPowers

        if (value <= 49) return 0
        else if (value <= 99) return 1
        else if (value <= 149) return 2
        else if (value <= 199) return 3
        else if (value <= 250) return 4
    }
    const startArray = [start1, start2, start3, start4, start5];
    const bg_time = Background_time.Waifu();

    return (
        <div className="rounded-b-lg overflow-hidden bg-white shadow-xl">
            <div className="h-64 relative" style={{ backgroundImage: `url(${bg_time})`, backgroundSize: 'cover', backgroundPosition: 'bottom'}}>
                <img alt="start-img" src={startArray[startValue(element?.waifuPower) || 0]} className="absolute top-2 right-2 z-[26]" />
                <GetImageAdn genData={"0003000300300030030003000300030030003003000300300030030030303"} />
                <div className="absolute bottom-2 right-2 p-1 border-2 rounded-xl border-[#DB1A99] bg-[#F0C1DC] z-[26]">{durability.now || 0}/{durability.complete || 50}</div>
            </div>
            <div className="p-2 text-xl font-bold text-center">
                2 Farmer Waifus
            </div>
        </div>
    )
}

export default Waifu;